
<i18n locale="th" lang="yaml" >
 page.title : "{name} :: แก้ไข Drone Model"
 page.description : "แก้ไขข้อมูลทั่วไปของ Drone Model รุ่น {name}"
 update_model.success : "ทำการแก้ไข Drone รุ่น {name} ในระบบเรียบร้อย"
 update_model.confirm.title : "ยืนยันแก้ไขข้อมูล Drone Model"
 update_model.confirm.message : "คุณต้องการแก้ไขข้อมูลของ Drone Model นี้ ?"
</i18n>

<template>
	<div class="page page-padding">
		<my-page-header
			:title="$t('page.title',{ name: model.name })"
			:description="$t('page.description',{ name: model.name })"/>

		<DroneModelForm
			ref="formRef"
			:loading="loading"
			mode="update"
			@submit="handleSubmit">
			<template v-slot:submitSlot="{ formLoading }">
				<a-button
					:disabled="formLoading"
					icon="left"
					size="large"
					:style="{ 'margin-right': '4px' }"
					@click="goBack">
					{{$t('common.back')}}
				</a-button>
				<a-button
					:loading="formLoading"
					type="info"
					html-type="submit"
					class="btn-submit"
					icon="edit"
					size="large">
					{{$t('common.save')}}
				</a-button>
			</template>
		</DroneModelForm>
	</div>
</template>

<script>
import PageMixin from '@mixins/PageMixin.vue'
import DroneModelForm from '@components/drone/DroneModelForm.vue'
import axios from "axios"

export default {
	page() {
		return {
			title : this.$t('page.title',{name : this.model.name})
		}
	} ,
	components : {
		DroneModelForm,
	} ,
	mixins: [PageMixin],
	data(){
		return {
			model : {} ,
			loading : false,
		}
	} ,
	watch: {
		$route : 'fetchData' ,
	} ,
	mounted() {
		this.fetchData()
	} ,
	methods : {
		fetchData() {
			const modelId = this.$route.params.id;
			if (!modelId)
				return

			this.showPageLoading(true)
			this.clearBreadcrumbParams('modelName')
			axios.get("/api/drone-models/"+modelId).then((response)=>{
				this.model = response.data.data.drone_model;
				this.$refs.formRef.updateModelForm(this.model)

				this.addBreadcrumbParams({'modelName' : this.model.name})
			}).catch((error) => {
				this.fetchError(error);
			}).finally(()=>{
				this.hidePageLoading()
			})
		} ,
		handleSubmit(formData) {
			this.$confirm({
				title : this.$t('update_model.confirm.title') ,
				content : this.$t('update_model.confirm.message') ,
				maskClosable : true,
				onOk : () => {
					this.loading = true
					axios.put("/api/drone-models/"+this.model.id,formData).then((response) => {
						const newModel = response.data.data.drone_model
						this.$open({name : 'drone_model/view',params : {id : newModel.id}});
						this.$message.success(this.$t('update_model.success',{"name" : newModel.name}))
					}).catch((error) => {
						this.$refs.formRef.formErrors(error)
					}).finally(()=>{
						this.loading = false;
					});
				}
			})
		} ,
		goBack() {
			this.$open({name:'drone_model/view',params:{id:this.model.id}})
		}
	} ,
}
</script>
